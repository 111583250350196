import {Card, Grid, Image, Select, Snippet, Text} from '@geist-ui/core';
import axios from 'axios';
import {Link} from 'gatsby';
import React, {useState} from 'react';
import {useEffect} from 'react';

const Playground = ({uid}) => {
    const [channelList, setChannelList] = useState([]);
    const [channelSlug, setChannelSlug] = useState(null);
    const [feedType, setFeedType] = useState('json');
    const [apiLink, setApiLink] = useState('https://api.output.ws/content');

    useEffect(() => {
        axios({
            url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
            method: 'post',
            data: {
                uid,
            },
        }).then(({data}) => {
            setChannelList(data);
        });
    }, []);

    const handler = (val) => {
        const chanelIds = val.join('-');
        setChannelSlug(chanelIds);
    };
    return (
        <div className="playground">
            <Text h3>Make api endpoints to share with your clients</Text>
            <br/>
            <Text style={{margin: 0}}>Select the channels</Text>
            <Select
                placeholder="Choose channel"
                onChange={handler}
                width="300px"
                multiple
                initialValue={[]}
            >
                {channelList.map((ch, key) => (
                    <Select.Option value={btoa(ch.cId)} key={key}>
                        <img
                            src={ch.thumbnail}
                            width="20px"
                            height="20px"
                            style={{borderRadius: '50%'}}
                            alt={ch.title}
                        />
                        {ch.title}
                    </Select.Option>
                ))}
            </Select>
            <br/>
            <br/>
            <Text style={{margin: 0}}>Feed type</Text>
            <Select
                placeholder="Feed Type"
                onChange={(v) => setFeedType(v)}
                width="300px"
                initialValue="json"
            >
                <Select.Option value="json">JSON</Select.Option>
                <Select.Option value="xml">xml</Select.Option>
            </Select>
            <br/>
            <br/>
            <Text style={{margin: 0}}>Copy this api & share</Text>
            <Snippet
                symbol=">"
                text={`${apiLink}/${channelSlug}?feedType=${feedType}`}
                width="100%"
            />
        </div>
    );
};

export default Playground;
