import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Button, Space, Modal, message, Avatar, PageHeader } from 'antd';
import axios from 'axios';
import { DeleteOutlined, YoutubeOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditChannel from './EditChannel';
import AddChannel from "./AddChannel";

const { Title, Paragraph } = Typography;
const ChannelsDashboard = ({ uid }) => {
    const [channelList, setChannelList] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false); // Define drawerVisible state
    const [isAddChannelVisible, setIsAddChannelVisible] = useState(false);


    useEffect(() => {
        axios({
            url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
            method: 'post',
            data: { uid },
        }).then(({ data }) => {
            setChannelList(data);
        });
    }, [uid]);

    const showAddChannelDrawer = () => {
        setIsAddChannelVisible(true);
    };

    // Function to refresh the channel list (you might already have this or need to implement it)
    const refreshChannelsList = async () => {
        try {
            const response = await axios.post(`${process.env.GATSBY_API_BASE_URL}/getChannel`, { uid });
            if (response.data) {
                setChannelList(response.data);
            }
        } catch (error) {
            message.error('Failed to refresh channels. Please try again.');
            console.error('Error fetching channels:', error);
        }
    };

    const closeAddChannelDrawer = () => {
        setIsAddChannelVisible(false);
    };
    const removeThisChannel = (ch) => {
        Modal.confirm({
            title: 'Are you sure you want to remove this channel?',
            icon: < DeleteOutlined />,
            content: 'This action cannot be undone and will permanently delete the channel data.',
            okText: 'Yes, remove it',
            okType: 'danger',
            cancelText: 'No, cancel',
            onOk: () => {
                axios({
                    url: `${process.env.GATSBY_API_BASE_URL}/removeChannel`,
                    method: 'post',
                    data: {
                        uid,
                        ytChannelId: ch.cId,
                    },
                }).then(({ data }) => {
                    // Assuming your API responds with a successful message upon deletion
                    // Update the state to remove the channel from the list
                    setChannelList(channelList.filter(channel => channel.cId !== ch.cId));
                    message.success('Channel successfully removed.');
                }).catch((error) => {
                    // Error handling
                    message.error('Failed to remove the channel. Please try again.');
                    console.error('Error removing channel:', error);
                });
            },
            onCancel: () => {
                console.log('Removal cancelled');
            },
        });
    };

    const showEditDrawer = (channel) => {
        setSelectedChannel(channel);
        setDrawerVisible(true);
    };


    // Function to update the channel information
    const updateChannelInfo = async (updatedInfo) => {
        try {
            // API call to the updateChannel endpoint with the updated channel information
            const response = await axios.post(`${process.env.GATSBY_API_BASE_URL}/updateChannel`, {
                channelId: updatedInfo.channelId, // make sure cId is passed correctly from the form
                title: updatedInfo.title,
                description: updatedInfo.description,
                userId: uid, // assuming you have the user's ID stored in uid
                thumbnail: updatedInfo.thumbnail
            });

            if (response.status === 200) {
                // Explicitly updating the title and description of the matched channel
                const updatedChannelList = channelList.map(channel => {
                    if (channel.cId === updatedInfo.channelId) {
                        return {
                            ...channel,
                            title: updatedInfo.title,
                            description: updatedInfo.description,
                            thumbnail: updatedInfo.thumbnail
                        };
                    }
                    return channel;
                });

                setChannelList(updatedChannelList);
                message.success('Channel successfully updated.');
            } else {
                throw new Error('Failed to update the channel info.');
            }
        } catch (error) {
            // Handle errors here
            message.error('Failed to update the channel. Please try again.');
            console.error('Error updating channel:', error);
        }
        // Close the drawer
        closeEditDrawer();
    };

    // Function to close the drawer
    const closeEditDrawer = () => {
        setDrawerVisible(false);
        setSelectedChannel(null);
    };

    // Function to toggle the expanded state of a description
    const toggleExpand = (cId) => {
        const updatedChannelList = channelList.map((channel) => {
            if (channel.cId === cId) {
                return { ...channel, expanded: !channel.expanded };
            }
            return channel;
        });
        setChannelList(updatedChannelList);
    };

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Your Channels"
                extra={[
                <Button key="1" size='large' type="primary" icon={<PlusOutlined />} onClick={showAddChannelDrawer} >
                        Add Channel
                    </Button>,
                ]}
            />

            <Row gutter={[16, 16]}>
            {channelList.length > 0 ? (
                channelList.map((channel) => (
                    <Col xs={24} sm={24} md={12} key={channel.cId}>
                        <Card
                            hoverable
                            actions={[
                                <Button
                                    type="text"
                                    onClick={() => window.open(`https://www.youtube.com/channel/${channel.cId}`, '_blank')}
                                    style={{ fontSize: '12px' }}
                                >
                                    <YoutubeOutlined style={{ color: '#FF0000', marginRight: '4px', fontSize: '16px' }} />
                                </Button>,
                                <Button
                                    type="text"
                                    icon={<EditOutlined style={{ fontSize: '16px' }} />}
                                    onClick={() => showEditDrawer(channel)}
                                />,
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined style={{ fontSize: '16px' }} />}
                                    onClick={() => removeThisChannel(channel)}
                                />,
                            ]}
                        >
                            <Space size="middle">
                                <Avatar size={64} src={channel.thumbnail} />
                                <Title level={4} style={{ marginBottom: 0, fontSize: '16px' }}>
                                    {channel.title}
                                </Title>
                            </Space>
                            <Paragraph
                                ellipsis={channel.expanded ? false : { rows: 2, expandable: false }}
                                onClick={() => toggleExpand(channel.cId)}
                                style={{ cursor: 'pointer', fontSize: '14px', marginTop: '10px' }}
                            >
                                {channel.description}
                            </Paragraph>
                        </Card>
                    </Col>
                ))
            ) : (
                <Col span={24}>
                    <Paragraph style={{ textAlign: 'center' }}>There are no channels added.</Paragraph>
                </Col>
            )}
        </Row>
        {selectedChannel && (
            <EditChannel
                visible={drawerVisible}
                onClose={closeEditDrawer}
                channelData={selectedChannel}
                updateChannel={updateChannelInfo}
            />
        )}
       {isAddChannelVisible && (
            <AddChannel
                    visible={isAddChannelVisible}
                    onClose={closeAddChannelDrawer}
                    refreshChannelsList={refreshChannelsList}
                    uid={uid}
                />
            )}
        </>
    );
};

export default ChannelsDashboard;