import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Usage from "./usage";
import Billing from "./billing";
import { Firebase } from "../../config/firebase";
import { Link, navigate } from "gatsby";
import DistributorDash from "./distributor/distributorDash";
import AllChannels from "./distributor/allChannels";
import { MdDynamicFeed, MdLogout } from "react-icons/md";
import { IoPricetagOutline } from "react-icons/io5";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { TbChartInfographic } from "react-icons/tb";
import { TiCreditCard } from "react-icons/ti";

const distributorMenuItems = [
  {
    label: "Feeds",
    icon: <MdDynamicFeed />,
    link: "/dashboard",
  },
  {
    label: "Channels",
    icon: <MdOutlineVideoLibrary />,
    link: "/dashboard/channels",
  },
  {
    label: "Usage",
    icon: <TbChartInfographic />,
    link: "/dashboard/usage",
  },
  {
    label: "Billing",
    icon: <TiCreditCard />,
    link: "/dashboard/billing",
  },
];

const DistributorBox = ({
  location,
  uid,
  userType,
  userObj,
  setTriggerPage,
}) => {
  const logoutUser = () => {
    Firebase.auth()
      .signOut()
      .then(
        function () {
          // Sign-out successful.
        },
        function (error) {
          // An error happened.
        }
      );
  };

  const channelInbox = userObj?.channelInbox || [];
  const bottomMenuItems = [
    {
      label: "Pricing",
      icon: <IoPricetagOutline />,
      onClick: () => {
        navigate("/pricing");
      },
      buttonType: "link",
    },
    {
      icon: <MdLogout />,
      label: "Log Out",
      onClick: () => {
        logoutUser();
      },
      buttonType: "danger",
    },
  ];

  useEffect(() => {
    if (channelInbox.length === 0) navigate("/dashboard/channels");
  }, []);
  return (
    <div className="dash-content">
      <div
        className="side-menu"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
          background:
            "linear-gradient(0deg, rgba(58, 56, 77, 0.04) 0%, rgba(58, 56, 77, 0.04) 100%), #FFF",
        }}
      >
        <div>
          <div style={{ paddingBottom: 20 }}>
            <Link to="/" className="logo">
              <img src="/output.png" width="30px" height="30px" alt="logo" />
              <h3>Output</h3>
            </Link>
          </div>
          <div className="sidenav-menu-items">
            {distributorMenuItems.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={
                  location.pathname === item.link
                    ? "side-menu-item active"
                    : "side-menu-item"
                }
              >
                {item.icon}
                <span>{item.label}</span>
              </Link>
            ))}
          </div>
        </div>
        <div className="sidenav-menu-items">
          {bottomMenuItems.map((item, index) => (
            <div
              key={index}
              className="side-menu-item"
              onClick={item.onClick}
              style={{ cursor: "pointer" }}
            >
              {item.icon}
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="dashboard-content">
        <Router>
          <DistributorDash
            path="/dashboard"
            uid={uid}
            location={location}
            userObj={userObj}
          />
          <AllChannels
            path="/dashboard/channels"
            uid={uid}
            location={location}
            channelInbox={channelInbox}
            setTriggerPage={setTriggerPage}
          />
          <Usage path="/dashboard/usage" uid={uid} location={location} />
          <Billing
            path="/dashboard/billing"
            uid={uid}
            location={location}
            userObj={userObj}
          />
        </Router>
      </div>
    </div>
  );
};

export default DistributorBox;
