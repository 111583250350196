export const integrations = [
  "Amazon FreeVee",
  "Amazon Prime",
  "Amazon Fire",
  "Amazon Alexa",
  "DailyMotion",
  "Oovvuu",
  "Primis",
  "Video Elephant",
  "Youtube",
  "Facebook ",
  "Apple Music",
  "Apple TV",
  "Spotify",
  "TikTok ",
  "Rumble",
  "STN Video",
  "Tubi",
  "Pluto TV",
  "Atmosphere",
  "Xumo",
  "Insight TV",
  "Zeasn",
  "STIRR",
  "Taboola",
  "Samsung +",
  "Anuvu",
  "Spafax",
  "Hulu",
  "Reach TV",
  "Plex",
  "Stirr ",
  "Sling ",
  "Sony TV ",
  "Huawei",
];
