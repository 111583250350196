import { Text } from '@geist-ui/core';
import { Button, message } from 'antd';
import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Firebase } from '../../config/firebase';

const Billing = ({ userObj }) => {
      const openPortal = () => {
            if (userObj.stripe_customer) {
                  message.info('Redirecting to payment billing page...');
                  const data = JSON.stringify({
                        customer_id: userObj.stripe_customer,
                  });

                  const config = {
                        method: 'post',
                        url: `${process.env.GATSBY_API_BASE_URL}/customer_portal_link`,
                        headers: {
                              'Content-Type': 'application/json',
                        },
                        data,
                  };

                  axios(config)
                        .then(function (response) {
                              const { url } = response.data;
                              window.open(url);
                        })
                        .catch(function (error) {
                              console.log(error);
                        });
            } else {
                  message.info('You have no subscription yet.');
            }
      };
      return (
            <div>
                  <Text h3>Billing</Text>
                  <Text>
                        You can manage your subscription, view invoices, upgrade
                        plan, & cancel the subscription.
                  </Text>
                  <Button
                        type="primary"
                        style={{
                              fontSize: 14,
                        }}
                        onClick={() => openPortal()}
                  >
                        Open Billing Portal
                  </Button>
            </div>
      );
};

export default Billing;
