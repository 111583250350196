import React, { useState, useRef } from 'react';
import { Tag, Input } from 'antd';

const TagsInput = ({ value = [], onChange }) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = (e) => {
        // This will prevent the default form submit behavior
        e.preventDefault();
        let newTags = [...value];
        if (inputValue && newTags.indexOf(inputValue) === -1) {
            newTags = [...newTags, inputValue];
        }
        onChange(newTags);
        setInputValue('');
    };

    const handleClose = (removedTag) => {
        const newTags = value.filter(tag => tag !== removedTag);
        onChange(newTags);
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #d9d9d9', borderRadius: '4px', padding: '4px' }}>
            {value.map((tag, index) => (
                <Tag
                    key={tag}
                    closable
                    onClose={() => handleClose(tag)}
                    style={{ margin: '2px' }}
                >
                    {tag}
                </Tag>
            ))}
            <Input
                ref={inputRef}
                type="text"
                size="small"
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={handleInputConfirm}
                onBlur={handleInputConfirm}
                placeholder="New tag"
                style={{ flex: '1', border: 'none', outline: 'none' }}
            />
        </div>
    );
};

export default TagsInput;
