import React, { useState, useEffect } from 'react';
import { Drawer, Form, Input, Button, Upload, message } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';

const EditChannel = ({ visible, onClose, channelData, updateChannel }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState('');
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        if (visible && channelData) {
            form.setFieldsValue({
                title: channelData.title,
                description: channelData.description,
            });
            setImagePreview(channelData.thumbnail);
        }
    }, [channelData, form, visible]);

    const getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.onload = () => callback(reader.result);
        reader.readAsDataURL(file);
    };
    const handleImageChange = ({ file }) => {
        setLoading(true);
        getBase64(file, imageUrl => {
            setImagePreview(imageUrl);
            setImageFile(file);
            setLoading(false);
        });
    };

    const onSave = async () => {
        try {
            let thumbnailUrl = channelData.thumbnail;
            if (imageFile) {
                const formData = new FormData();
                formData.append('thumbnail', imageFile);
                const uploadResponse = await axios.post(`${process.env.GATSBY_API_BASE_URL}/uploadFile`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                thumbnailUrl = uploadResponse.data.fileRemoteLink ?? '';
            }

            form
                .validateFields()
                .then((values) => {
                    const updatedData = {
                        ...values,
                        channelId: channelData.cId,
                        thumbnail: thumbnailUrl
                    };
                    updateChannel(updatedData); // Function to update channel in the parent component's state
                    onClose(); // Close the drawer
                })
                .catch((info) => {
                    console.log('Validate Failed.', info);
                });
        } catch (error) {
            message.error('Something went wrong !');
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Drawer
            title="Edit Channel"
            width={"30%"}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div style={{ textAlign: 'right'}}>
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={onSave} type="primary">
                        Save
                    </Button>
                </div>
            }
        >
            <Form form={form} layout="vertical" hideRequiredMark>
                <Form.Item
                    name="title"
                    label="Channel Name"
                    rules={[{ required: true, message: 'Please enter channel name' }]}
                >
                    <Input placeholder="Please enter channel name" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please enter channel description' }]}
                >
                    <Input.TextArea rows={4} placeholder="Please enter channel description" />
                </Form.Item>
                <Form.Item label="Thumbnail">
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={handleImageChange}
                    >
                        {imagePreview ? <img src={imagePreview} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default EditChannel;
