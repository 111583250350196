// https://www.placemark.io/
import React, { useEffect, useState } from "react";
import { Firebase } from "../config/firebase";
import { navigate } from "gatsby";

import "../styles/dashboard.scss";
import Seo from "../blocks/layout/seo";
import axios from "axios";
import { randomString } from "../config/functions";
import "../styles/dashboard.scss";
import PublisherDashboard from "../blocks/dashboard/PublisherDashboard";
import DistributorBox from "../blocks/dashboard/distributor";

const Dashboard = ({ location }) => {
  const [uId, setUId] = useState("");
  const [triggerPage, setTriggerPage] = useState(0);
  const [userType, setUserType] = useState("");
  const [userObj, setUserObj] = useState(null);

  async function onChange(user) {
    if (user) {
      const { data } = await axios({
        url: `${process.env.GATSBY_API_BASE_URL}/getUser`,
        method: "post",
        data: {
          uid: user.uid,
          email: user.email,
          userType: "publisher",
          apiKey: randomString(16, "aA#"),
        },
      });
      if (data) {
        setUserObj(data);
        setUserType(data.userType);
      }
      setUId(user.uid);
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, [triggerPage]);

  return (
    <div className="dashboard-container">
      <Seo title="Dashboard - Output" />

      {userType === "publisher" && (
        <PublisherDashboard uid={uId} location={location} userObj={userObj} />
      )}

      {userType === "distributor" && (
        <DistributorBox
          uid={uId}
          location={location}
          userType={userType}
          userObj={userObj}
          setTriggerPage={() => setTriggerPage(triggerPage + 1)}
        />
      )}
    </div>
  );
};

export default Dashboard;
