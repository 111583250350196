import { Snippet, Text } from '@geist-ui/core';
import { Avatar, Button, Input, message, Tooltip } from 'antd';
import React, { useEffect } from 'react';

import { FiCopy } from 'react-icons/fi';
import ChannelThumbnails from './channelThumbnails';

const DistributorDash = ({ userObj }) => {
      const triggerCopy = (endpointURL, mess) => {
            const textField = document.createElement('textarea');
            textField.innerText = endpointURL;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            message.success(mess);
      };

      return (
            <div>
                  <div
                        style={{
                              marginBottom: 20,
                        }}
                  >
                        <Text h3 style={{ margin: 0 }}>
                              Feeds with API
                        </Text>
                        <Text type="secondary">
                              Video content from 500+ YouTube channels with
                              single API.
                        </Text>
                        <ChannelThumbnails uid={userObj?.uid} />
                  </div>
                  <Text h4>API Key</Text>
                  <Text type="secondary">
                        Unique secreat key to access api endpoints.
                  </Text>
                  <Snippet
                        symbol="→"
                        text={userObj?.apiKey}
                        width="500px"
                        type="secondary"
                        onClick={() =>
                              triggerCopy(
                                    'jnjn3jn3jnm2jkmn1kmkm1kmk3m',
                                    'API key copied'
                              )
                        }
                  />
                  <br />
                  <Text h4>Channels List</Text>
                  <Text type="secondary">
                        List of all channels as JSON format
                  </Text>
                  <Snippet
                        symbol="→"
                        text={`${process.env.GATSBY_API_BASE_URL}/channels?apiKey=${userObj?.apiKey}`}
                        width="700px"
                        type="secondary"
                        onClick={() =>
                              triggerCopy(
                                    `${process.env.GATSBY_API_BASE_URL}/channels?apiKey=${userObj?.apiKey}`,
                                    'API endpoint copied'
                              )
                        }
                  />
                  <table className="documentation-table error-codes">
                        <thead>
                              <tr>
                                    <th>Object</th>
                                    <th>Description</th>
                              </tr>
                        </thead>
                        <tbody>
                              <tr>
                                    <td>
                                          <code>page</code>
                                    </td>
                                    <td>Pagination for next page.</td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>perPage</code>
                                    </td>
                                    <td>
                                          Limit number of results in one api
                                          call. (default: 100)
                                    </td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>sortBy</code>
                                    </td>
                                    <td>
                                          Name of the key by which you want to
                                          sort the response.
                                    </td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>sortOrder</code>
                                    </td>
                                    <td>
                                          The direction you want to sort. asc or
                                          desc
                                    </td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>q</code>
                                    </td>
                                    <td>
                                          search query param to filter results.
                                    </td>
                              </tr>
                        </tbody>
                  </table>
                  <br />
                  <Text h4>Video Content</Text>
                  <Text type="secondary">
                        List video contents as JSON or XML feed.
                  </Text>
                  <Snippet
                        symbol="→"
                        text={`${process.env.GATSBY_API_BASE_URL}/videos?apiKey=${userObj?.apiKey}`}
                        width="700px"
                        type="secondary"
                        onClick={() =>
                              triggerCopy(
                                    `${process.env.GATSBY_API_BASE_URL}/videos?apiKey=${userObj?.apiKey}`,
                                    'API endpoint copied'
                              )
                        }
                  />
                  <table className="documentation-table error-codes">
                        <thead>
                              <tr>
                                    <th>Object</th>
                                    <th>Description</th>
                              </tr>
                        </thead>
                        <tbody>
                              <tr>
                                    <td>
                                          <code>feedtype</code>
                                    </td>
                                    <td>
                                          Get response as JSON & XML. default:
                                          json. Options are <b>xml</b>,{' '}
                                          <b>json</b>
                                    </td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>page</code>
                                    </td>
                                    <td>Pagination for next page.</td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>perPage</code>
                                    </td>
                                    <td>
                                          Limit number of results in one api
                                          call. (default: 100)
                                    </td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>sortBy</code>
                                    </td>
                                    <td>
                                          Name of the key by which you want to
                                          sort the response.
                                    </td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>sortOrder</code>
                                    </td>
                                    <td>
                                          The direction you want to sort. asc or
                                          desc
                                    </td>
                              </tr>
                              <tr>
                                    <td>
                                          <code>q</code>
                                    </td>
                                    <td>
                                          search query param to filter results.
                                    </td>
                              </tr>
                        </tbody>
                  </table>
            </div>
      );
};

export default DistributorDash;
