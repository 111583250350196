import React, {useEffect, useState} from 'react';
import { Button, Popover, Select, DatePicker, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import axios from "axios";
const { RangePicker } = DatePicker;
const { Option } = Select;


const ContentFilter = ({ uid, onFilterApply }) => {
    const [open, setOpen] = useState(false);
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([]);

    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const { data } = await axios.post(`${process.env.GATSBY_API_BASE_URL}/getChannel`, { uid });
                setChannels(data);
            } catch (error) {
                console.error('Failed to fetch channels', error);
            }
        };
        fetchChannels();
    }, [uid]);

    const handleOpenChange = (visible) => {
        setOpen(visible);
    };

    const applyFilter = () => {
        const formattedDateRange = selectedDateRange ? selectedDateRange.map(date => date ? date.format('YYYY-MM-DD') : undefined): null;
        onFilterApply(selectedChannel, formattedDateRange);
        setOpen(false);
    };

    const clearFilters = () => {
        setSelectedChannel(null);
        setSelectedDateRange([]);
        onFilterApply(null, []);
    };

    const content = (
        <div>
            <div style={{ marginBottom: 5 }}>
                <Select
                    placeholder="Select a channel"
                    onChange={setSelectedChannel}
                    style={{
                        width: '100%',
                        fontSize: '14px'
                    }}
                    size="small"
                    value={selectedChannel}
                >
                    {channels.map(channel => (
                        <Option key={channel.cId} value={channel.cId}>{channel.title}</Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginBottom: 10 }}>
                <RangePicker
                    allowEmpty={[true, true]}
                    onChange={(dates) =>{
                        setSelectedDateRange(dates)}}
                    style={{ width: '100%', fontSize: '14px' }}
                    value={selectedDateRange}
                />
            </div>
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button
                onClick={clearFilters}
                style={{
                    width: '100%', // Match width with the above fields
                    padding: '4px 12px', // Adjust padding to match other buttons if needed
                    fontSize: '12px' // Match font size with the dashboard buttons
                }}
            >
                Clear
            </Button>
            <Button
                type="primary"
                onClick={applyFilter}
                style={{
                    width: '100%', // Match width with the above fields
                    padding: '4px 12px', // Adjust padding to match other buttons if needed
                    fontSize: '12px' // Match font size with the dashboard buttons
            }}>
                Apply
            </Button>
            </Space>
        </div>
    );

    return (
        <Popover
            content={content}
            title="Filter Content"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Button icon={<FilterOutlined />} />
        </Popover>
    );
};

export default ContentFilter;
