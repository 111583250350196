import React, { useEffect, useState } from "react";
import { Onboarding } from "../../../components/onboarding";
import axios from "axios";

const SocialMedia = ({ uid }) => {
  const [userObj, setUserObj] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      if (uid) {
        const { data } = await axios({
          url: `${process.env.GATSBY_API_BASE_URL}/getUser`,
          method: "post",
          data: {
            uid,
          },
        });
        console.log("UserObj", data);
        setUserObj(data);
      }
    };

    getUser();
  }, []);

  return (
    <div>
      {userObj && (
        <Onboarding uId={uid} userObj={userObj} state="socialMedia" />
      )}
    </div>
  );
};

export default SocialMedia;
