import React, { useState } from 'react';
import { Drawer, Form, Input, Button, message, Upload } from 'antd';
import { PlusOutlined, UploadOutlined, DeleteOutlined, YoutubeFilled } from '@ant-design/icons';
import axios from 'axios';
import * as helper from './../../../../utils/helper';

const AddChannel = ({ visible, onClose, refreshChannelsList, uid }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [youtubeChannelId, setYoutubeChannelId] = useState('');
    const [youtubeChannelData, setYoutubeChannelData] = useState({});

    const handleImageChange = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
        setImageFile(file);
        return false; // To prevent automatic upload
    };

    const handleRemoveImage = () => {
        setImagePreview('');
        setImageFile(null);
        form.setFieldsValue({ thumbnail: null }); // Optionally reset thumbnail field in form
    };

    const onSave = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            const formData = new FormData();
            let thumbnailUrl = '';
            if (imageFile) {
                console.log('trying to upload file');
                formData.append('thumbnail', imageFile);
                const uploadResponse = await axios.post(`${process.env.GATSBY_API_BASE_URL}/uploadFile`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                thumbnailUrl = uploadResponse.data.fileRemoteLink??'';
            }
            const channelDataToSave = {
                uid,
                channelTitle: values.name,
                description: values.description,
                thumbnailUrl: thumbnailUrl,
                youtubeChannelId: youtubeChannelId || '',
                youtubeUploadsId: youtubeChannelData.uploadId || '',
                ytStatistics: youtubeChannelData.statistics || {}
            };

            await axios.post(`${process.env.GATSBY_API_BASE_URL}/addChannelNew`, channelDataToSave);
            message.success('Channel added successfully');
            refreshChannelsList(); // Refresh the list to include the newly added channel
            onClose(); // Close the drawer
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
                message.error('Upgrade the subscription to add more channels.');
            } else {
                message.error('Failed to add the channel. Please try again.');
                console.error('Error adding channel:', error);
            }
        } finally {
            setLoading(false);
        }
    };
    const beforeUpload = (file) => {
        // You can set state here if you need to keep track of the file
        return false; // Return false so that antd doesn't upload the file right away
    };

    const fetchYoutubeData = async () => {
        try {
            const response = await axios.post(`${process.env.GATSBY_API_BASE_URL}/getYoutubeChannelDetails`, {
                params: { ytChannelId: youtubeChannelId }
            });
            const { channelData } = response.data;

            // Convert thumbnail URL to Blob and then to File
            const thumbnailResponse = await axios.get(channelData.thumbnail, { responseType: 'blob' });
            const thumbnailFile = new File([thumbnailResponse.data], youtubeChannelId? `${youtubeChannelId}.jpg`: `thumbnail_${Date.now()}.jpg`, { type: 'image/jpeg' });

            console.log(`Channel Info : ${JSON.stringify(channelData)}`);
            form.setFieldsValue({
                name: channelData.title,
                description: channelData.description,
                thumbnail: [thumbnailFile]
            });

            setImagePreview(channelData.thumbnail);
            setYoutubeChannelData(channelData);
            setImageFile(thumbnailFile);
        } catch (error) {
            message.error('Failed to fetch channel information. Please check the URL and try again.');
            console.error('Error fetching channel information:', error);
        }
    };

    return (
        <Drawer
            title="Add New Channel"
            width={720}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={onSave} type="primary" loading={loading}>
                        Save
                    </Button>
                </div>
            }
        >
            <Form form={form} layout="vertical" hideRequiredMark>
                <Form.Item
                    name="youtubeChannelId"
                    label="YouTube Channel ID"
                >
                    <Input
                        addonAfter={<YoutubeFilled onClick={fetchYoutubeData} />}
                        placeholder="Enter YouTube channel URL"
                        value={youtubeChannelId}
                        onChange={(e) => setYoutubeChannelId(e.target.value)}
                        onPressEnter={fetchYoutubeData}
                    />
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Channel Name"
                    rules={[{ required: true, message: 'Please input the channel name' }]}
                >
                    <Input placeholder="Input channel name" style={{ fontSize: '16px' }} />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please input the channel description' }]}
                >
                    <Input.TextArea rows={4} placeholder="Input channel description" style={{ fontSize: '16px' }}/>
                </Form.Item>
                <Form.Item
                    name="thumbnail"
                    label="Thumbnail"
                    rules={[{ required: true, message: 'Please upload the thumbnail' }]}
                >
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={handleImageChange}
                    >
                        {imagePreview ? (
                            <div>
                                <img src={imagePreview} alt="thumbnail" style={{ width: '100%', marginBottom: 10 }} />
                                <Button type="danger" icon={<DeleteOutlined />} onClick={handleRemoveImage}>Remove</Button>
                            </div>
                        ) : (
                            <PlusOutlined />
                        )}
                    </Upload>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default AddChannel;
