import React, { useState, useEffect } from 'react';
import { Drawer, Button, Table, message, Typography, Select, Tabs, Input } from 'antd';
import axios from 'axios';
import AddYoutubeChannel from '../channel/AddYoutubeChannel';
import ManualUpload from './ManualUpload';
import { randomString } from "../../../../config/functions";

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

function getMaxResImage(thumbnailsObj) {
    const { maxres, standard, high, medium } = thumbnailsObj;
    if (maxres) return maxres.url;
    if (standard) return standard.url;
    if (high) return high.url;
    if (medium) return medium.url;

    return thumbnailsObj?.default?.url;
}

const AddVideos = ({ uid, open, onClose, triggerRefresh, setTriggerRefresh, setOpen, contentList }) => {
    const [channelList, setChannelList] = useState([]);
    const [channelVideos, setChannelVideos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [playlistId, setPlaylistId] = useState('');
    const [selectedChannel, setSelectedChannel] = useState(null);

    const showDrawer = () => {
        setOpen(true);
    };

    const importSelectedVideos = () => {
        const dataArray = [];
        for (let n = 0; n < selectedVideos.length; n++) {
            const el = selectedVideos[n];
            el.uid = uid;
            el.objId = randomString(32, 'aA#');
            dataArray.push({ data: el });
        }

        axios({
            url: `${process.env.GATSBY_API_BASE_URL}/addVideosToProcess`,
            method: 'post',
            data: { dataArray, selectedVideos },
        }).then(({ data }) => {
            setOpen(false);
            setTriggerRefresh(triggerRefresh + 1);
        });
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedVideos(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: contentList.findIndex((x) => x.videoId === record.videoId) > -1,
            title: record.title,
        }),
    };

    const columns = [
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnails',
            key: 'thumbnails',
            render: (obj) => (
                <img
                    src={getMaxResImage(obj)}
                    alt="hh"
                    height="60px"
                />
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'videoId',
            dataIndex: 'videoId',
            key: 'videoId',
        },
        {
            title: 'videoPublishedAt',
            dataIndex: 'videoPublishedAt',
            key: 'videoPublishedAt',
        },
    ];

    const getYoutubeChannelVideos = () => {
        if (!selectedChannel) {
            message.error('Please select a channel first');
            return;
        }

        const queryParams = playlistId ? `&playlistId=${playlistId}` : `&uploadsId=${selectedChannel}`;
        axios({
            url: `${process.env.GATSBY_API_BASE_URL}/getChannelVideos?uid=${uid}${queryParams}`,
            method: 'get',
        }).then(({ data }) => {
            setChannelVideos(data.simpleArray);
            if (data.nextPageToken === undefined) {
                setNextPageToken(null);
            } else {
                setNextPageToken(data.nextPageToken);
            }
        }).catch((error) => {
            message.error('Unable to get videos. Ensure the playlist belongs to the selected channel.');
            setChannelVideos([]);
        });
    };

    useEffect(() => {
        if (open) {
            axios({
                url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
                method: 'post',
                data: { uid },
            }).then(({ data }) => {
                setChannelList(data);
            });
        }
    }, [uid, open]);

    return (
        <Drawer
            title="Add video content"
            placement="left"
            onClose={onClose}
            open={open}
            width="70%"
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="YouTube Channels" key="1">
                    <div>
                        <Text>Select the channel</Text>
                    </div>
                    <div>
                        <Select
                            placeholder="Choose channel"
                            onChange={(value) => {
                                setSelectedChannel(value);
                                setPlaylistId('');
                                setChannelVideos([]);
                            }}
                            style={{ width: '30%' }}
                            className="ant-select-custom"
                        >
                            {channelList.map((ch) => (
                                <Option
                                    value={ch.uploadsId}
                                    key={ch.uploadsId}
                                >
                                    {ch.title}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Input
                            placeholder="Enter playlist Id (optional)"
                            value={playlistId}
                            onChange={(e) => setPlaylistId(e.target.value)}
                            style={{ width: '30%' }}
                            disabled={!selectedChannel}
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Button
                            type="primary"
                            onClick={getYoutubeChannelVideos}
                        >
                            Fetch Videos
                        </Button>
                    </div>
                    <div>
                        <AddYoutubeChannel
                            openModal={openModal}
                            uid={uid}
                            closeModalBox={() => setOpenModal(false)}
                            setTriggerPage={() => showDrawer()}
                            channelList={channelList}
                        />
                        <Text
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: 'rgba(0, 0, 0, 0.45)', // This is a typical secondary text color in Ant Design
                                fontSize: 'smaller' // For smaller text
                            }}
                            onClick={() => setOpenModal(true)}
                        >
                            → Add a YouTube Channel
                        </Text>
                    </div>
                    <br />
                    {selectedVideos.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: 20,
                            }}
                        >
                            <Button
                                type="primary"
                                style={{ fontSize: 14 }}
                                onClick={importSelectedVideos}
                            >
                                Import {selectedVideos.length} videos
                            </Button>
                        </div>
                    )}
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        dataSource={channelVideos}
                        columns={columns}
                        rowKey="id"
                        pagination={false}
                    />
                </TabPane>
                <TabPane tab="Manual Upload Video" key="2">
                    <ManualUpload
                        uid={uid}
                        setTriggerRefresh={() => setTriggerRefresh(triggerRefresh + 1)}
                        setOpen={() => setOpen(false)}
                        channelList={channelList}
                    />
                </TabPane>
                <TabPane tab="Tik Tok [Coming Soon..]" key="3">
                </TabPane>
            </Tabs>
        </Drawer>
    );
};

export default AddVideos;
