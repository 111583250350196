import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Text } from '@geist-ui/core';
import {
      StockOutlined,
      ArrowUpOutlined,
      FieldTimeOutlined,
} from '@ant-design/icons';
import { Card, Col, Row, Statistic, Empty } from 'antd';
import moment from 'moment';
import TotalAPICalls from './distributor/totalAPICalls';
import { formatUsageData } from '../../config/functions';

const Usage = ({ uid }) => {
      const [usageObj, setUsageObj] = useState(null);

      useEffect(() => {
            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/getAPIUsage`,
                  method: 'post',
                  data: {
                        uid,
                  },
            }).then(({ data }) => {
                  const { error } = data;
                  if (!error) setUsageObj(data);
            });
      }, []);
      return (
            <div>
                  {usageObj === null ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : (
                        <>
                              <Row gutter={16}>
                                    <Col span={8}>
                                          <Card>
                                                <Statistic
                                                      title="Consumed"
                                                      value={usageObj.count}
                                                      valueStyle={{
                                                            color: '#3f8600',
                                                      }}
                                                      prefix={
                                                            <ArrowUpOutlined />
                                                      }
                                                />
                                          </Card>
                                    </Col>
                                    <Col span={8}>
                                          <Card>
                                                <Statistic
                                                      title="Total api limit"
                                                      value={usageObj.reqLimits}
                                                      valueStyle={{
                                                            color: '#3f8600',
                                                      }}
                                                      prefix={<StockOutlined />}
                                                />
                                          </Card>
                                    </Col>
                                    <Col span={8}>
                                          <Card>
                                                <Statistic
                                                      title="Limit reset"
                                                      value={`${moment
                                                            .unix(
                                                                  usageObj.startTime
                                                            )
                                                            .add(1, 'M')
                                                            .fromNow()}`}
                                                      valueStyle={{
                                                            color: '#3f8600',
                                                      }}
                                                      prefix={
                                                            <FieldTimeOutlined />
                                                      }
                                                />
                                          </Card>
                                    </Col>
                              </Row>
                              <TotalAPICalls
                                    usageData={formatUsageData(
                                          usageObj.countObject
                                    )}
                              />
                        </>
                  )}
            </div>
      );
};

export default Usage;
