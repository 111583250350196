import { Form, Input, message, Button, Alert } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { integrations as allIntegrations } from "../data/tech";
import axios from "axios";

// const steps = [
//   {
//     title: "What Integrations Do You Need?",
//     description:
//       "We'll tailor recommendations based on the integrations you choose",
//   },
//   {
//     title: "Social Media Links",
//     description: "Add your social media links to your profile",
//   },
// ];

export const Onboarding = ({ uId, state, userObj }) => {
  const [form] = Form.useForm();
  // const [currentStep, setCurrentStep] = useState(0);
  const [integrations, setIntegrations] = useState(
    userObj?.onboarding?.integrations
  );
  const youtube = Form.useWatch("youtube", form);
  const tiktok = Form.useWatch("tiktok", form);
  const instagram = Form.useWatch("instagram", form);
  const [isSuccessfullyUpdated, setIsSuccessfullyUpdated] = useState(false);

  const handleSelectIntegration = (integration) => {
    if (integrations.includes(integration)) {
      setIntegrations(integrations.filter((i) => i !== integration));
    } else {
      setIntegrations([...integrations, integration]);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      youtube: userObj.onboarding.youtube,
      tiktok: userObj.onboarding.tiktok,
      instagram: userObj.onboarding.instagram,
    });
  }, [userObj.onboarding]);

  const handleNextStep = async () => {
    // if (currentStep + 1 === steps.length) {
    await handleFinishUpdateUser();
    // } else {
    // setCurrentStep((currentStep) => currentStep + 1);
    // }
  };

  // const handleBackStep = () => {
    // if (currentStep === 0) {
    //   return;
    // }
    // setCurrentStep((currentStep) => currentStep - 1);
  // };

  const handleFinishUpdateUser = async () => {
    try {
      await axios({
        url: `${process.env.GATSBY_API_BASE_URL}/updateUser`,
        method: "post",
        data: {
          uId,
          onboarding: {
            isComplete: true,
            integrations,
            youtube,
            tiktok,
            instagram,
          },
        },
      });

      setIsSuccessfullyUpdated(true);

      setTimeout(() => {
        setIsSuccessfullyUpdated(false);
      }, 3000);
    } catch (error) {
      console.error("Error updating user:", error);
      message.error("Error updating user");
    }
  };

  const isAllIntegrationsSelected = useMemo(() => {
    return integrations.length === allIntegrations.length;
  }, [integrations]);

  const handleSelectAll = () => {
    if (isAllIntegrationsSelected) {
      setIntegrations([]);
    } else {
      setIntegrations(allIntegrations);
    }
  };

  // const isNextStepDisabled = useMemo(() => {
  //   if (currentStep === 0) {
  //     return integrations.length === 0;
  //   }
  //   return false;
  // }, [currentStep, integrations]);

  return (
    // <div className="onboarding-wrapper">
    <div className="onboarding-container" style={{ marginTop: 42 }}>
      {/* <div style={{ width: 300 }}>
          <div className="progressLine">
            <div className="progressFill" style={{ width: progressWidth }} />
          </div>
        </div> */}
      {state === "integrations" && (
        <div>
          <h2>What Integrations Do You Need?</h2>
          <p>
            We'll tailor recommendations based on the integrations you choose
          </p>
          <Button
            type="secondary"
            onClick={handleSelectAll}
            style={{ marginBottom: 12 }}
          >
            {isAllIntegrationsSelected ? "Deselect All" : "Select All"}
          </Button>
          <div className="onboarding-integration-selector">
            {allIntegrations.map((item, index) => (
              <div
                onClick={() => handleSelectIntegration(item)}
                key={index}
                className={`integration-selector-item ${
                  integrations.includes(item) ? "selected" : ""
                }`}
              >
                <div>{item}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {state === "socialMedia" && (
        <div>
          <h2>Social Media Links</h2>
          <p>Add your social media links to your profile</p>
          <Form
            layout={"vertical"}
            form={form}
            initialValues={{ layout: "horizontal" }}
            style={{ width: 600 }}
          >
            <Form.Item label="Youtube Channel" name={"youtube"}>
              <Input
                placeholder="https://www.youtube.com/@ycombinator"
                defaultValue={userObj.onboarding.youtube}
              />
            </Form.Item>
            <Form.Item label="Instagram" name={"instagram"}>
              <Input
                placeholder="https://www.instagram.com/username"
                defaultValue={userObj.onboarding.instagram}
              />
            </Form.Item>
            <Form.Item label="TikTok" name={"tiktok"}>
              <Input
                placeholder="https://www.tiktok.com/@username"
                defaultValue={userObj.onboarding.tiktok}
              />
            </Form.Item>
          </Form>
        </div>
      )}
      <div>
        <Button
          type="primary"
          size="large"
          onClick={handleNextStep}
          style={{ marginTop: 32 }}
        >
          Save
        </Button>
      </div>
      <div style={{ paddingTop: 16 }}>
        {isSuccessfullyUpdated && state === "integrations" && (
          <Alert
            message="Integrations successfully updated"
            type="success"
            showIcon
          />
        )}
        {isSuccessfullyUpdated && state === "socialMedia" && (
          <Alert
            message="Social media successfully updated"
            type="success"
            showIcon
          />
        )}
      </div>
    </div>
    // </div>
  );
};
