import React from "react";
import { Router, navigate } from "@reach/router";
import Billing from "./billing";

import { MdOutlineDynamicFeed } from "react-icons/md";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { IoPlayOutline } from "react-icons/io5";
import { Firebase } from "../../config/firebase";

import { Link } from "gatsby";
import Playground from "./publisher/Playground";
import FeedBuilder from "./publisher/FeedBuilder";
import ChannelsDashboard from "./publisher/channel/ChannelsDashboard";
import ContentDashboard from "./publisher/content/ContentDashboard";
import Integrations from "./publisher/integrations";
import SocialMedia from "./publisher/socialMedia";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { TbBrandTiktok } from "react-icons/tb";
import { MdDynamicFeed, MdLogout } from "react-icons/md";
import { TiCreditCard } from "react-icons/ti";
import { IoPricetagOutline } from "react-icons/io5";

const publisherMenuItems = [
  {
    label: "Content",
    icon: <MdOutlineDynamicFeed />,
    link: "/dashboard",
  },
  {
    label: "Your Channels",
    icon: <MdOutlineVideoLibrary />,
    link: "/dashboard/my-channels",
  },
  {
    label: "Playground",
    icon: <IoPlayOutline />,
    link: "/dashboard/playground",
  },
  {
    label: "Integrations [BETA]",
    icon: <MdOutlineIntegrationInstructions />,
    link: "/dashboard/integrations",
  },
  {
    label: "Social Media [BETA]",
    icon: <TbBrandTiktok />,
    link: "/dashboard/social-media",
  },
  {
    label: "Feed Builder",
    icon: <MdDynamicFeed />,
    link: "/dashboard/feed-builder",
  },
  {
    label: "Billing",
    icon: <TiCreditCard />,
    link: "/dashboard/billing",
  },
];

const PublisherDashboard = ({ location, uid, userObj }) => {
  const logoutUser = () => {
    Firebase.auth()
      .signOut()
      .then(
        function () {
          // Sign-out successful.
        },
        function (error) {
          // An error happened.
        }
      );
  };

  const bottomMenuItems = [
    {
      label: "Pricing",
      icon: <IoPricetagOutline />,
      onClick: () => {
        navigate("/pricing");
      },
      buttonType: "link",
    },
    {
      icon: <MdLogout />,
      label: "Log Out",
      onClick: () => {
        logoutUser();
      },
      buttonType: "danger",
    },
  ];
  return (
    <div className="dash-content">
      <div
        className="side-menu"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
          background:
            "linear-gradient(0deg, rgba(58, 56, 77, 0.04) 0%, rgba(58, 56, 77, 0.04) 100%), #FFF",
        }}
      >
        <div>
          <div style={{ paddingBottom: 20 }}>
            <Link to="/" className="logo">
              <img src="/output.png" width="30px" height="30px" alt="logo" />
              <h3>Output</h3>
            </Link>
          </div>
          <div className="sidenav-menu-items">
            {publisherMenuItems.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={
                  location.pathname === item.link
                    ? "side-menu-item active"
                    : "side-menu-item"
                }
              >
                {item.icon}
                <span>{item.label}</span>
              </Link>
            ))}
          </div>
        </div>
        <div className="sidenav-menu-items">
          {bottomMenuItems.map((item, index) => (
            <div
              key={index}
              className="side-menu-item"
              onClick={item.onClick}
              style={{ cursor: "pointer" }}
            >
              {item.icon}
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="dashboard-content">
        <Router>
          <ContentDashboard
            path="/dashboard"
            uid={uid}
            location={location}
            userDetails={userObj}
          />
          <ChannelsDashboard
            path="/dashboard/my-channels"
            uid={uid}
            location={location}
          />
          <Playground
            path="/dashboard/playground"
            uid={uid}
            location={location}
          />
          <Integrations
            path="/dashboard/integrations"
            uid={uid}
            location={location}
            userObj={userObj}
          />
          <SocialMedia
            path="/dashboard/social-media"
            uid={uid}
            location={location}
            userObj={userObj}
          />
          <FeedBuilder
            path="/dashboard/feed-builder"
            uid={uid}
            location={location}
          />
          <Billing
            path="/dashboard/billing"
            uid={uid}
            location={location}
            userObj={userObj}
          />
        </Router>
      </div>
    </div>
  );
};

export default PublisherDashboard;
