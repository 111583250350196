import React, { useEffect } from 'react';
import { Drawer, Form, Input, Button, Typography, Divider, message } from 'antd';
import TagsInput from '../../../shared/tags';
import axios from 'axios';

const { Text } = Typography;

const EditContent = ({ visible, onClose, contentData }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (contentData) {
        form.setFieldsValue({
            title: contentData['title'],
            description: contentData['description'],
            keywords: contentData?.snippet?.keywords || []
        });
        }
    }, [contentData, form]);

    const handleUpdate = async (values) => {
        const updatedContentData = {
            ...contentData,
            title: values.title,
            description: values.description,
            snippet: {
                ...contentData.snippet,
                keywords: values.keywords,
            }
        };

        try {
            const response = await axios.post(
                `${process.env.GATSBY_API_BASE_URL}/updateContent/${updatedContentData.objId}`,
                updatedContentData
            );

            if (response && response.status === 200) {
                console.log('Content updated successfully', response.data);
                message.success('Content updated successfully!');
                onClose();
            } else {
                message.error('Failed to update content. Please try again.');
            }
        } catch (error) {
            console.error('Error updating content:', error);
            message.error('An error occurred while updating the content.');
        }
    };

    return (
        <Drawer
            title="Edit Content"
            width='70%'
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            className="edit-content-drawer"
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={() => form.submit()} type="primary">
                        Update
                    </Button>
                </div>
            }
        >
            <Form form={form} layout="vertical" onFinish={handleUpdate}>
                <Form.Item name="title" label="Title">
                    <Input/>
                </Form.Item>
                <Form.Item name="description" label="Description">
                    <Input.TextArea style={{ fontSize: '14px' }} rows={4}/>
                </Form.Item>
                <Form.Item name="keywords" label="Keywords">
                    <TagsInput />
                </Form.Item>
                <Divider/>
                <div className="metadata">
                    <Text strong>Metadata</Text>
                    <div>
                        <Text>Channel: </Text>
                        {contentData && <Text>{contentData?.channelTitle || ''}</Text>}
                    </div>
                    <div>
                        <Text>Publish Date: </Text>
                        {contentData && <Text>{contentData?.videoPublishedAt || ''}</Text>}
                    </div>
                    <div>
                        <Text>GUID: </Text>
                        {contentData && <Text>{contentData?.snippet?.guid || ''}</Text>}
                    </div>
                    <div>
                        <Text>Duration: </Text>
                        {contentData && <Text>{contentData?.videoDuration || ''}</Text>}
                    </div>
                    <div>
                        <Text>Resolution: </Text>
                        {contentData && (
                            <Text>
                                {contentData?.snippet?.width || ''} x {contentData?.snippet?.height || ''}
                            </Text>
                        )}
                    </div>
                </div>
            </Form>
        </Drawer>
    );
};

export default EditContent;
