import React, { useState, useEffect } from 'react';
import {Text, Select, Snippet} from '@geist-ui/core';
import { DatePicker, TimePicker, message } from 'antd';
import axios from 'axios';
import '../../../styles/ant-style.less';

const { Option } = Select;

const FeedBuilder = ({ uid }) => {
    const [channels, setChannels] = useState([]);
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [orientation, setOrientation] = useState('');
    const [feedUrl, setFeedUrl] = useState('https://api.output.ws/content');
    const [minDuration, setMinDuration] = useState(null);
    const [maxDuration, setMaxDuration] = useState(null);

    const format = 'HH:mm:ss';

    const convertToSeconds = (momentObj) => {
        if (momentObj) {
            const hours = momentObj.hours();
            const minutes = momentObj.minutes();
            const seconds = momentObj.seconds();
            return hours * 3600 + minutes * 60 + seconds;
        }
        return null;
    };

    const handleMinDurationChange = (time) => {
        if (maxDuration && time && time.isAfter(maxDuration)) {
            message.error('Min Duration should be less than Max Duration.');
            return;
        }
        setMinDuration(time);
    };

    const handleMaxDurationChange = (time) => {
        if (minDuration && time && time.isBefore(minDuration)) {
            message.error('Max Duration should be greater than Min Duration.');
            return;
        }
        setMaxDuration(time);
    };
    const clearOrientation = () => {
        setOrientation('');
    };

    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const { data } = await axios({
                    url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
                    method: 'post',
                    data: { uid },
                });
                setChannels(data);
            } catch (error) {
                console.error("Error fetching channels:", error);
            }
        };
        fetchChannels();
    }, [uid]);

    useEffect(() => {
        let url = 'https://api.output.ws/content';
        const params = new URLSearchParams();

        const minDurationSeconds = convertToSeconds(minDuration);
        const maxDurationSeconds = convertToSeconds(maxDuration);

        if (minDurationSeconds !== null) {
            params.append('minDuration', minDurationSeconds);
        }

        if (maxDurationSeconds !== null) {
            params.append('maxDuration', maxDurationSeconds);
        }

        if (selectedChannels.length > 0) {
            params.append('channels', selectedChannels.join(','));
        }

        if (startDate) {
            params.append('startDate', startDate.format('YYYY-MM-DD'));
        }

        if (endDate) {
            params.append('endDate', endDate.format('YYYY-MM-DD'));
        }

        if (orientation) {
            params.append('orientation', orientation);
        }

        const encodedQueryParams = btoa(params.toString());
        if (params.toString()) {
            url = `${url}?options=${encodedQueryParams}`;
        }

        setFeedUrl(url);
    }, [selectedChannels, startDate, endDate, orientation, minDuration, maxDuration]);

    return (
        <div className="feedBuilder">
            <Text h3>Make customizable endpoints to share with your clients</Text>

            <Select
                placeholder="Choose channel"
                onChange={setSelectedChannels}
                width="300px"
                multiple
                initialValue={[]}
            >
                {channels.map((ch, key) => (
                    <Select.Option value={ch.cId} key={key}>
                        <img
                            src={ch.thumbnail}
                            width="20px"
                            height="20px"
                            style={{borderRadius: '50%'}}
                            alt={ch.title}
                        />
                        {ch.title}
                    </Select.Option>
                ))}
            </Select>

            <div style={{ display: 'flex', justifyContent: 'left', marginTop: '10px', height: '40px' }}>
                <DatePicker
                    placeholder="Start Date"
                    onChange={date => setStartDate(date)}
                    style={{ width: '300px' }}
                />
                <DatePicker
                    placeholder="End Date"
                    onChange={date => setEndDate(date)}
                    style={{ width: '300px', marginLeft: '10px' }}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'left', marginTop: '10px', height: '40px' }}>
                <TimePicker
                    value={minDuration}
                    onChange={handleMinDurationChange}
                    format={format}
                    placeholder="Min Duration"
                    showNow={false}
                    style={{ width: '300px', marginRight: '10px' }}
                />

                <TimePicker
                    value={maxDuration}
                    onChange={handleMaxDurationChange}
                    format={format}
                    placeholder="Max Duration"
                    showNow={false}
                    style={{ width: '300px' }}
                />
            </div>

            <div style={{ marginTop: '10px' }}>
                <Select
                    placeholder="Orientation"
                    onChange={value => {
                        if (value === "") {
                            setOrientation(null); // clear orientation
                        } else {
                            setOrientation(value);
                        }
                    }}
                    value={orientation}  // Set the current value
                    width="300px"
                >
                    <Option value="">None</Option>  {/* This is the clear option */}
                    <Option value="horizontal">Horizontal</Option>
                    <Option value="vertical">Vertical</Option>
                </Select>
            </div>

            <br/>
            <Snippet symbol=">" text={`${feedUrl}`} width="100vh" />
        </div>
    );
};

export default FeedBuilder;
