import React, { useState } from 'react';
import { Button, Form, Input, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import { randomString } from '../../../../config/functions';
import { Select, Text } from '@geist-ui/core';
import TagsInput from '../../../shared/tags';

const { Dragger } = Upload;

const ManualUpload = ({ uid, setTriggerRefresh, setOpen, channelList }) => {
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0);
    const [videoWidth, setVideoWidth] = useState(0);
    const [videoHeight, setVideoHeight] = useState(0);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [selectedVideoSource, setSelectedVideoSource] = useState('manual');
    const [tags, setTags] = useState([]);

    const videoSources = [
        { label: 'Youtube', value: 'youtube' },
        { label: 'TikTok', value: 'tiktok' },
        { label: 'Manual', value: 'manual' }
    ];

    const handleVideoSourceChange = (value) => {
        setSelectedVideoSource(value);
    };
    const onFinish = (values) => {
        setSubmitting(true);
        const { description, title, videoFile, videoThumbnail } = values;

        const videoFileURL = videoFile.file.response.fileRemoteLink;
        const thumbnailFileURL = videoThumbnail.file.response.fileRemoteLink;
        const videoId = randomString(12, 'aA#');

        const manualVideoObj = {
            uid,
            objId: randomString(32, 'aA#'),
            description,
            videoId,
            channelTitle: selectedChannel?.title,
            channelId: selectedChannel?.cId,
            id: videoId,
            source: selectedVideoSource,
            status: 'done',
            thumbnails: {
                default: {
                    url: thumbnailFileURL,
                },
            },
            title,
            url: videoFileURL,
            videoDuration,
            snippet: {
                tags: tags,
                width: videoWidth,
                height: videoHeight
            }
        };

        axios({
            url: `${process.env.GATSBY_API_BASE_URL}/addManualVideo`,
            method: 'post',
            data: { manualVideoObj },
        }).then(({ data }) => {
            setOpen(false);
            setTriggerRefresh(prev => prev + 1);
            setSubmitting(false);
            setTags([]);
        });
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}

            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const loadVideo = (file) =>
        new Promise((resolve, reject) => {
            try {
                let video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = function () {
                    resolve(this);
                };

                video.onerror = function () {
                    message.error(`Invalid video. Please select a video file.`);
                    reject('Invalid video. Please select a video file.');
                };

                video.src = window.URL.createObjectURL(file);
            } catch (e) {
                message.error(`There are some issues into your video`);
                reject(e);
            }
        });

    const imageProps = {
        name: 'file',
        action: `${process.env.GATSBY_API_BASE_URL}/uploadFile`,
        multiple: false,
        maxCount: 1,
        data: {
            dirPath: 'thumbnails',
        },

        onChange(info) {
            const { status } = info.file;
            setLoading(true);
            if (status !== 'uploading') {
                console.log('uploading');
            }
            if (status === 'done') {
                setLoading(false);
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const videoProps = {
        name: 'file',
        action: `${process.env.GATSBY_API_BASE_URL}/uploadFile`,
        multiple: false,
        accept: 'video/*',
        maxCount: 1,
        data: {
            dirPath: 'videos',
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading');
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            const { type } = e.dataTransfer.files[0];
            const [videoType] = type.split('/');
            if (videoType !== 'video') {
                message.error('Only video files allowed.');
            }
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handler = (val) => {
        setSelectedChannel(channelList.find((x) => x.uploadsId === val));
    };

    return (
        <>
            <div className="submit-form">
                <Text>Select the channel to upload video to</Text>
                <Select
                    placeholder="Choose channel"
                    onChange={handler}
                    width="300px"
                >
                    {channelList.map((ch, key) => (
                        <Select.Option
                            value={ch.uploadsId}
                            key={key}
                        >
                            {ch.title}
                        </Select.Option>
                    ))}
                </Select>

                <br/>
                <br/>

                <Text>Select the video source</Text>
                <Select
                    placeholder="Choose source"
                    value={selectedVideoSource}
                    onChange={handleVideoSourceChange}
                    width="300px"
                >
                    {videoSources.map((source, index) => (
                        <Select.Option
                            value={source.value}
                            key={index.toString()} // Use index as key if you're sure the list won't change
                        >
                            {source.label}
                        </Select.Option>
                    ))}
                </Select>

                <br/>
                <br/>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        label="Upload Video"
                        name="videoFile"
                        rules={[
                            {
                                required: true,
                                message: 'Video is required!',
                            },
                        ]}
                    >
                        <Dragger
                            {...videoProps}
                            beforeUpload={async (file) => {
                                const video = await loadVideo(file);
                                setVideoDuration(video.duration);
                                setVideoWidth(video.videoWidth);
                                setVideoHeight(video.videoHeight);
                                return true;
                            }}
                            name="videoFile"
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag video</p>
                        </Dragger>
                    </Form.Item>
                    <Form.Item
                        label="Upload Video Thumbnail"
                        name="videoThumbnail"
                        help="1280x720px or higher recommended & JPG, PNG, GIF. Max size: 10MB. The first image will be used as preview"
                    >
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            // fileList={fileList}
                            className="avatar-uploader"
                            showUploadList={true}
                            accept=".png,.jpg,.gif"
                            {...imageProps}
                            beforeUpload={(file) => {
                                const isLt2M = file.size / 1024 / 1024 < 10;
                                if (!isLt2M) {
                                    message.error('Image must smaller than 10MB!');
                                }
                                return isLt2M;
                            }}

                            // onChange={handleChange}
                        >
                            {uploadButton}
                        </Upload>
                    </Form.Item>
                    <br/>
                    <Form.Item
                        label="Title"
                        name="title"
                    >
                        <Input required/>
                    </Form.Item>
                    <Form.Item label="Tags" >
                        <TagsInput value={tags} onChange={setTags} />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea required/>
                    </Form.Item>
                    <Form.Item
                        label="Select category"
                        name="category"
                    >
                        <Select
                            //   onChange={handler}
                        >
                            <Select.Option value="Autos &amp; Vehicles">Autos &amp; Vehicles</Select.Option>
                            <Select.Option value="Comedy">Comedy</Select.Option>
                            <Select.Option value="Education">Education</Select.Option>
                            <Select.Option value="Entertainment">Entertainment</Select.Option>
                            <Select.Option value="Film &amp; Animation">Film &amp; Animation</Select.Option>
                            <Select.Option value="Gaming">Gaming</Select.Option>
                            <Select.Option value="Howto &amp; Style">Howto &amp; Style</Select.Option>
                            <Select.Option value="Music">Music</Select.Option>
                            <Select.Option value="News &amp; Politics">News &amp; Politics</Select.Option>
                            <Select.Option value="Nonprofits &amp; Activism">Nonprofits &amp; Activism</Select.Option>
                            <Select.Option value="People &amp; Blogs">People &amp; Blogs</Select.Option>
                            <Select.Option value="Pets &amp; Animals">Pets &amp; Animals</Select.Option>
                            <Select.Option value="Science &amp; Technology">Science &amp; Technology</Select.Option>
                            <Select.Option value="Sports">Sports</Select.Option>
                            <Select.Option value="Travel &amp; Events">Travel &amp; Events</Select.Option>
                        </Select>
                    </Form.Item>
                    <br/>
                    <br/>

                    <Form.Item>
                        <Button
                            type="primary"
                            name="submit-btn"
                            htmlType="submit"
                            loading={submitting}
                        >
                            Add video content
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default ManualUpload;
