import React, { useEffect, useState } from 'react';
import { Avatar, Tooltip } from 'antd';
import axios from 'axios';

const ChannelThumbnails = ({ uid }) => {
      const [thumbnails, setThumbnails] = useState([]);

      useEffect(() => {
            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
                  method: 'post',
            }).then(({ data }) => {
                  setThumbnails(data);
            });
      }, []);

      return (
            <Avatar.Group
                  maxCount={5}
                  maxStyle={{
                        color: '#f56a00',
                        backgroundColor: '#fde3cf',
                  }}
            >
                  {thumbnails.map((thumb, key) => (
                        <Tooltip
                              key={key}
                              placement="topLeft"
                              title={thumb.title}
                        >
                              <Avatar src={thumb.thumbnail} />
                        </Tooltip>
                  ))}
            </Avatar.Group>
      );
};

export default ChannelThumbnails;
