exports.randomString = function (length, chars) {
      let mask = '';
      if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
      if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (chars.indexOf('#') > -1) mask += '0123456789';
      if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
      let result = '';
      for (let i = length; i > 0; --i)
            result += mask[Math.round(Math.random() * (mask.length - 1))];
      return result;
};

exports.formatUsageData = function (obj) {
      const graphData = [];
      Object.keys(obj).forEach(function (key) {
            graphData.push({ date: key, requests: obj[key] });
      });
      return graphData;
};
