import React, { useState, useEffect } from "react";
import { Onboarding } from "../../../components/onboarding";
import axios from 'axios';

const Integrations = ({ uid }) => {
  const [userObj, setUserObj] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      if (uid) {
        const { data } = await axios({
          url: `${process.env.GATSBY_API_BASE_URL}/getUser`,
          method: "post",
          data: {
            uid,
          },
        });
        console.log("UserObj", data);
        setUserObj(data);
      }
    };

    getUser();
  }, []);

  return (
    <div className="playground">
      {userObj && <Onboarding uId={uid} state="integrations" userObj={userObj} />}
    </div>
  );
};

export default Integrations;
