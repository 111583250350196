import { Button, Card, Grid, Image, Text } from '@geist-ui/core';
import axios from 'axios';
import { Link } from 'gatsby';
import { RiCheckboxFill } from 'react-icons/ri';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { message } from 'antd';

const AllChannels = ({ uid, channelInbox, setTriggerPage }) => {
      const [channelList, setChannelList] = useState([]);

      useEffect(() => {
            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
                  method: 'post',
            }).then(({ data }) => {
                  setChannelList(data);
            });
      }, []);

      const checkIsAdded = (id) => {
            return channelInbox.findIndex((x) => x === id) > -1 ? true : false;
      };

      const addChannelToAccount = (id) => {
            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/addChannelToAccount`,
                  method: 'post',
                  data: {
                        uid,
                        id,
                  },
            }).then(({ data }) => {
                  const { error } = data;

                  if (error) {
                        message.info(error);
                  } else {
                        message.success('channel added into your account.');
                        setTriggerPage();
                  }
            });
      };

      return (
            <>
                  <Text h3>Add channels into your account</Text>
                  <Grid.Container gap={1.5}>
                        {channelList.map((ch, key) => (
                              <Grid xs={8} justify="center" key={key}>
                                    <Card width="100%">
                                          <img
                                                style={{
                                                      width: 40,
                                                      height: 40,
                                                      borderRadius: '50%',
                                                }}
                                                src={ch.thumbnail}
                                          />
                                          <Text h4 my={0}>
                                                {ch.title}
                                          </Text>
                                          {checkIsAdded(ch.cId) ? (
                                                <Button
                                                      scale={1 / 2}
                                                      icon={<RiCheckboxFill />}
                                                >
                                                      Added
                                                </Button>
                                          ) : (
                                                <Button
                                                      onClick={() =>
                                                            addChannelToAccount(
                                                                  ch.cId
                                                            )
                                                      }
                                                      scale={1 / 2}
                                                >
                                                      Add channel
                                                </Button>
                                          )}
                                    </Card>
                              </Grid>
                        ))}
                  </Grid.Container>
            </>
      );
};

export default AllChannels;
